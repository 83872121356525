import { useEffect, useState } from "react";
import StoryLazy from "react-insta-stories";
import leftArrow from "./assets/left.png";
import rightArrow from "./assets/right.png";

// const story = [
//   "https://www.nna-leb.gov.lb/media/cache/slider_list/uploads/images/7c83ac4ccead1be1782b1b941816847f.png",
//   "https://www.nna-leb.gov.lb/media/cache/slider_list/uploads/images/13844e07778670e94cd5517d3860dbe8.png",
// ];

function App() {
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Using the fetch function
        const response = await fetch(
          "https://buycodestaging.wecansync.com/api/products/syncbarcode/get-info?deviceUUID=1234"
        );
        const result = await response.json();

        const newArray = result.data.data.story.map((item) => {
          if (item.type === "video") {
            return {
              url: item.content,
              type: item.type,
            };
          } else {
            return item.content; 
          }
        });

        setStories(newArray);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }

      // console.log(loading);
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", width:'100vw', height:'100vh' }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "500px",
          position: "relative",
          margin: 0,
          
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        >
          <div
            style={{
              // background: "rgba(0,0,0,0.1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <img src={leftArrow} alt="Left" style={{ height: '35px' }}/>
          </div>
          <div
            style={{
              // background: "rgba(0,0,0,0.1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <img src={rightArrow} alt="Right" style={{ height: '35px' }}/>
          </div>
        </div>

        <StoryLazy
          stories={stories}
          preloadCount={3}
          loop
          keyboardNavigation
          defaultInterval={8000}
          loader
          // onStoryEnd={(s, st) => console.log("story ended", s, st)}
          // onAllStoriesEnd={(s, st) => console.log("all stories ended", s, st)}
          // onStoryStart={(s, st) => console.log("story started", s, st)}
          // onNext={() => console.log("next button pressed")}
          // onPrevious={() => console.log("previous button pressed")}
          width={"100%"}
          height={"100%"}
          storyStyles={{width:'100%', height:'100%', minWidth:'100%', margin:'auto'}}
          storyContainerStyles={{ overflow: "hidden", display:'flex',alignItems:'center' }}
        />
      </div>
    </div>
  );
}

export default App;
